import "./assets/SCSS/app.scss";
import React, { useState, useRef } from "react";
import { PrivateRoute } from "./pages/Private-Routing";
import { toast, ToastContainer } from "react-toastify";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import ErrorPage from "./pages/Error";
import ForgotPassword from "./pages/ForgotPassword";
import VerificationCode from "./pages/verifyOtp";
import CreateNewPassword from "./pages/CreateNewPassword";
import SignUp from "./pages/SignUp";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import AllTelemedicine from "./pages/AllTelemedicine";
import AllSpecialist from "./pages/AllSpecialist";
import AllConditions from "./pages/AllConditions";
import AllPediatrician from "./pages/AllPediatrician";
import ProfileLayout from "./pages/ProfileLayout";
import Profile from "./pages/Profile";
import ChangePassword from "./pages/ChangePassword";
import FamilyMembers from "./pages/FamilyMembers";
import MyPlans from "./pages/MyPlans";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import PrivacyPolicyWithoutLogin from "./pages/PrivacyPolicyWithoutLogin";
import TermsOfUse from "./pages/TermsOfUse";
import { fetchToken, messaging, onMessageListener } from "./config/firebase";
import { approveRejectReq } from "./features/notifications/approvals";
import AdditionalFacility from "./pages/AdditionalFacility";
import MessageFromChairman from "./pages/MessageFromChairman";
import AdditionalFacilityDetail from "./pages/AdditionalFacilityDetail";
import SpousePlan from "./pages/SpousePlan";
import { onMessage } from "firebase/messaging";
import Chat from "./chat/admin/Chat";
import ChatDefault from "./chat/admin/ChatDefault";
import ChatListing from "./chat/admin/ChatListing";
import Admin from "./chat/admin/Admin";
import AdminUpdatePass from "./chat/admin/AdminUpdatePass";
import AdminAllProviders from "./chat/admin/AdminAllProviders";
import AdminForgotPassword from "./chat/admin/AdminForgotPassword";
import AdminCreateNewPassword from "./chat/admin/AdminCreateNewPassword";
import AdminVerificationCode from "./chat/admin/AdminVerifyOtp";
import Employees from "./pages/Employees";
import EmployeesSummarry from "./pages/EmployeesSummary";
import ImportedEmployees from "./pages/ImportedEmployees";
import ErrorImportedEmployees from "./pages/ErrorImportedEmployees";
import Notification from "./pages/Notification";

// Site Main App page
const App = () => {
	const [isTokenFound, setTokenFound] = useState(false);
	onMessage(messaging, (payload) => {
		toastId.current = toast(
			<div className="approval-popup">
				<h6>{payload.notification.title}</h6>
				<p>{payload.notification.body}</p>
				<div className="d-flex">
					<button
						className="btn success mr-1 mb-1 sm"
						onClick={() => {
							approveRejectReq("accept", payload.data["gcm.notification.userSSN"]);
							toast.dismiss(toastId.current);
						}}>
						Approve
					</button>
					<button
						className="btn danger outline mb-1 sm"
						onClick={() => {
							approveRejectReq("reject", payload.data["gcm.notification.userSSN"]);
							toast.dismiss(toastId.current);
						}}>
						Reject
					</button>
				</div>
			</div>,
			{
				autoClose: false,
				closeOnClick: false,
				draggable: false,
				toastId: "approvalNotification"
			}
		);
	});
	fetchToken(setTokenFound);
	const toastId = useRef(null);

	return (
		<div className="page-wrapper">
			<ToastContainer newestOnTop={true} position="top-right" className="ec-toast-container" />
			<Router>
				
					<Routes>
						<Route exact path="/" element={<PrivateRoute />}>
							<Route exact path="/" element={<Home />} />
							<Route path="/find-telemedicine" element={<AllTelemedicine />} />
							<Route path="/all-specialist" element={<AllSpecialist />} />
							<Route path="/all-conditions" element={<AllConditions />} />
							<Route path="/all-providers" element={<AllPediatrician />} />
							<Route path="/additional-benefits" element={<AdditionalFacility />}>
								<Route index path="/additional-benefits" element={<AdditionalFacilityDetail />} />
								<Route path="/additional-benefits/:id" element={<AdditionalFacilityDetail />} />
							</Route>
							<Route path="/about-us" element={<AboutUs />} />
							<Route path="/message-from-chairman" element={<MessageFromChairman />} />
							{/* <Route path="/telemedicine/:id" element={<TelemedicineDetail />} /> */}
							<Route path="/profile" element={<ProfileLayout />}>
								<Route index path="/profile" element={<Profile />} />
								<Route path="/profile/change-password" element={<ChangePassword />} />
								<Route path="/profile/family-members" element={<FamilyMembers />} />
								<Route path="/profile/my-plans" element={<MyPlans />} />
								<Route path="/profile/spouse-plan/:id" element={<SpousePlan />} />
							</Route>
							<Route path="/employees" element={<ProfileLayout />}>
								<Route index path="/employees" element={<Employees />} />
								<Route  path="/employees/imported" element={<ImportedEmployees />} />
								<Route path="/employees/error_imported" element={<ErrorImportedEmployees />} />
								<Route path="/employees/employees-summarry/:id" element={<EmployeesSummarry />} />
							</Route>
							<Route path="/policy" element={<PrivacyPolicy />} />
							<Route path="/terms" element={<TermsOfUse />} />
							<Route path="/admin/chat" element={<Chat />}>
								<Route index path="/admin/chat" element={<ChatDefault />} />
								<Route path="/admin/chat/:id" element={<ChatListing />} />
							</Route>
							<Route path="/admin/update-password" element={<AdminUpdatePass />} />
							<Route path="/admin/all-providers" element={<AdminAllProviders />} />
							<Route path="/notifications" element={<Notification />} />
						</Route>
						<Route path="/admin">
							<Route index path="/admin" element={<Admin />} />
							<Route path="/admin/forgot-password" element={<AdminForgotPassword />} />
							<Route path="/admin/verify-otp" element={<AdminVerificationCode />} />
							<Route path="/admin/create-new-password" element={<AdminCreateNewPassword />} />
						</Route>

						<Route path="/login" element={<Login />} />
						<Route path="/signup" element={<SignUp />} />
						<Route path="/forgot-password" element={<ForgotPassword />} />
						<Route path="/verify-otp" element={<VerificationCode />} />
						<Route path="/create-new-password" element={<CreateNewPassword />} />
						<Route path="*" element={<ErrorPage />} />
						<Route path="/privacy-policy" element={<PrivacyPolicyWithoutLogin />} />
					</Routes>
				
			</Router>
		</div>
	);
};

export default App;