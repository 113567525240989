import CONFIG from "../config/api.config";
import axios from "axios";

axios.defaults.baseURL = CONFIG.axiosBaseUrl;

let cancelToken;

// Logout User
export const logoutUser = async () => {
	return await axios.post(
		CONFIG.logout,
		{},
		{
			headers: {
				Authorization: "Bearer " + localStorage.getItem("encore-auth-token"),
			},
		}
	);
};

// Logout Admin User
export const logoutAdminUser = async () => {
	return await axios.post(
		CONFIG.adminLogoutURL,
		{
			headers: {
				Authorization: "Bearer " + localStorage.getItem("encore-auth-token"),
			},
		}
	);
};

/* Update User Info */
export const getUserInfo = async () => {
	return await axios.get(CONFIG.getUserInfo, {
		// cancelToken: cancelToken.token,
		headers: {
			Authorization: "Bearer " + localStorage.getItem("encore-auth-token"),
			"Content-Type": "application/json",
		},
	});
};

/* Update Spouse Request */
export const updateSpouseRequest = async (approval, userSSN) => {
	return await axios.post(
		CONFIG.verifyNotification,
		{
			status: approval,
			userSSN,
		},
		{
			headers: {
				Authorization: "Bearer " + localStorage.getItem("encore-auth-token"),
				"Content-Type": "application/json",
			},
		}
	);
};

// Get Common Condition
export const getCommonConditionList = async (reqData, token) => {
	return await axios.post(CONFIG.getCommonCondition, reqData, {
		headers: {
			Authorization: "Bearer " + token,
			"Content-Type": "application/json",
		},
	});
};

// Get Pediatrix Details
export const getPediatrixDetailsList = async (reqData, token) => {
	if (cancelToken) {
		cancelToken.cancel("Old Req Cancelled");
	}
	cancelToken = axios.CancelToken.source();
	return await axios.post(CONFIG.getPediatrixDetails, reqData, {
		cancelToken: cancelToken.token,
		headers: {
			Authorization: "Bearer " + token,
			"Content-Type": "application/json",
		},
	});
};

export const getProvidersList = async (reqData, token) => {
	if (cancelToken) {
		cancelToken.cancel("Old Req Cancelled");
	}
	cancelToken = axios.CancelToken.source();
	return await axios.post(CONFIG.getProviders, reqData, {
		cancelToken: cancelToken.token,
		headers: {
			Authorization: "Bearer " + token,
			"Content-Type": "application/json",
		},
	});
};

// Get Common Specialist
export const getCommonSpecialistList = async (reqData, token) => {
	return await axios.post(CONFIG.getCommonSpecialist, reqData, {
		headers: {
			Authorization: "Bearer " + token,
			"Content-Type": "application/json",
		},
	});
};

// Get Telemedicine
export const getTelemedicineList = async (reqData, token) => {
	return await axios.post(CONFIG.getTelemedicine, reqData, {
		headers: {
			Authorization: "Bearer " + token,
			"Content-Type": "application/json",
		},
	});
};

// Change Password
export const changeUserPassword = async (userPassword, reqData, token) => {
	return await axios.post(
		CONFIG.changePassword,
		{
			userPassword,
		},
		{
			headers: {
				Authorization: "Bearer " + localStorage.getItem("encore-auth-token"),
				"Content-Type": "application/json",
			},
		}
	);
};

// Update New Password
export const updateNewPassword = async (reqData, userDetail) => {
	return await axios.post(
		CONFIG.updateNewPassword,
		{
			userPassword: reqData.userPassword,
		},
		{
			headers: {
				Authorization: "Bearer " + userDetail.user.access_token,
				// access_token: userDetail.user.access_token,
				"Content-Type": "application/json",
			},
		}
	);
};

// User Delete
export const deleteUser = async () => {
	return await axios.get(CONFIG.deleteUser, {
		headers: {
			Authorization: "Bearer " + localStorage.getItem("encore-auth-token"),
			"Content-Type": "application/json",
		},
	});
};

// Message From Chairman
export const messageFromChairman = async () => {
	return await axios.get(CONFIG.messageFromChairman, {
		headers: {
			Authorization: "Bearer " + localStorage.getItem("encore-auth-token"),
			"Content-Type": "application/json",
		},
	});
};

// About Us
export const aboutUs = async () => {
	return await axios.get(CONFIG.aboutUs, {
		headers: {
			Authorization: "Bearer " + localStorage.getItem("encore-auth-token"),
			"Content-Type": "application/json",
		},
	});
};

// List Of Additional Facility
export const listOfAdditionalFacility = async () => {
	return await axios.get(CONFIG.listOfAdditionalFacility, {
		headers: {
			Authorization: "Bearer " + localStorage.getItem("encore-auth-token"),
			"Content-Type": "application/json",
		},
	});
};

// List Of resource documents
export const getResourceDocumentsList = async () => {
	return await axios.get(CONFIG.getResourceDocumentsList, {
		headers: {
			Authorization: "Bearer " + localStorage.getItem("encore-auth-token"),
			"Content-Type": "application/json",
		},
	});
};

// Get User Plan Details
export const getUserPlanDetails = async (id) => {
	return await axios.post(
		CONFIG.getUserPlanDetails,
		{
			id: id,
		},
		{
			headers: {
				Authorization: "Bearer " + localStorage.getItem("encore-auth-token"),
				"Content-Type": "application/json",
			},
		}
	);
};

// Additional Facility Details
export const additionalFacilityDetails = async (id) => {
	return axios.post(
		CONFIG.additionalFacilityDetails,
		{
			id: id,
		},
		{
			headers: {
				Authorization: "Bearer " + localStorage.getItem("encore-auth-token"),
				"Content-Type": "application/json",
			},
		}
	);
};

// Refresh Token
export const refreshToken = async (userSSN) => {
	return await axios.post(CONFIG.refreshToken, {
		userSSN: userSSN,
	});
};

// Get chat User listing
export const getChatUserListing = async () => {
	return await axios.post(CONFIG.getChatUserListing, {
		"userId": "1",
		"page": 0
	}, {
		headers: {
			Authorization: "Bearer " + localStorage.getItem("encore-auth-token"),
			"Content-Type": "application/json",
		},
	});
}

// Get user Chat Listing
export const getUserChatList = async (userId, uniqueId, page, isAdmin = false) => {
	return await axios.get(CONFIG.userChatList + `?userId=${userId}&uniqueId=${uniqueId}&page=${page}${isAdmin ? '&isAdmin=true' : ''}`, {
		headers: {
			Authorization: "Bearer " + localStorage.getItem("encore-auth-token"),
			"Content-Type": "application/json",
		},
	});
}

// Get chat User listing
export const terminateChatSession = async (uniqueId, userId) => {
	return await axios.post(CONFIG.terminateChatSession, {
		"uniqueId": uniqueId,
		"userId": userId
	}, {
		headers: {
			Authorization: "Bearer " + localStorage.getItem("encore-auth-token"),
			"Content-Type": "application/json",
		},
	});
}

// Get chat User listing
export const markTermCondition = async (uniqueId, userId) => {
	return await axios.get(CONFIG.markTermCondition, {
		headers: {
			Authorization: "Bearer " + localStorage.getItem("encore-auth-token"),
			"Content-Type": "application/json",
		},
	});
}

// Get User Plan Details
export const getAllEmployees = async (params) => {
	return await axios.get(
		CONFIG.allEmployees,
		{
			params,
			headers: {
				Authorization: "Bearer " + localStorage.getItem("encore-auth-token"),
				"Content-Type": "application/json",
			},
		}
	);
};
// Get User Plan Details
export const getEmployeesDetails = async (id) => {
	return await axios.get(
		CONFIG.employeeDetail + '/' + id,
		{
			headers: {
				Authorization: "Bearer " + localStorage.getItem("encore-auth-token"),
				"Content-Type": "application/json",
			},
		}
	);
};

export const changeEmployeeStatus = async (id, status) => {
	return await axios.post(
		CONFIG.changeEmployeeStatus + '/' + id,
		{
			userStatus: +status
		},
		{
			headers: {
				Authorization: "Bearer " + localStorage.getItem("encore-auth-token"),
				"Content-Type": "application/json",
			},
		}
	);
};


export const getUserStatus = async () => {
	return await axios.get(
		CONFIG.userStatus,

		{
			headers: {
				Authorization: "Bearer " + localStorage.getItem("encore-auth-token"),
				"Content-Type": "application/json",
			},
		}
	);
};

export const downloadSampleFile = async () => {
	return await axios.get(
		CONFIG.downloadSampleFile,
		{
			headers: {
				Authorization: "Bearer " + localStorage.getItem("encore-auth-token"),
				"Content-Type": "application/json",
			},
		}
	);
};

export const importFile = async (form_data) => {
	return await axios.post(
		CONFIG.importFile,
			form_data,
			{
				headers: {
					Authorization: "Bearer " + localStorage.getItem("encore-auth-token"),
					"Content-Type": "multipart/form-data",
				},
			}
	);
};

export const spouceChildDeleteDetails = async (userId, parentUserId) => {
	return await axios.delete(
		CONFIG.spouceChildDeleleDetails,
		{
			headers: {
				Authorization: "Bearer " + localStorage.getItem("encore-auth-token"),
				"Content-Type": "application/json",
			},
			data: {
				"parentUserId": `${parentUserId}`,
				"userId": `${userId}`
			}
		}
	);
};
// file upload for upload card
export const fileUploadCard = async (params, form_data) => {
	return await axios.post(
		CONFIG.uploadCardFile + `?id=${params}`,
		form_data,
		{
			headers: {
				Authorization: "Bearer " + localStorage.getItem("encore-auth-token"),
				// "Content-Type": "application/json",
				'Content-Type': 'multipart/form-data'
			},
		},
		// form_data
	);
};

export const deleteCard = async (userId) => {
	return await axios.delete(
		CONFIG.deleleCardDetails,
		{
			headers: {
				Authorization: "Bearer " + localStorage.getItem("encore-auth-token"),
				"Content-Type": "application/json",
			},
			data: {
				"userId": `${userId}`
			}
		}
	);
};

// export users 
export const exportUsersDetails = async (params) => {
	return await axios.get(
		CONFIG.exportUser,
		{
			params,
			headers: {
				Authorization: "Bearer " + localStorage.getItem("encore-auth-token"),
				"Content-Type": "application/json",
			},
		}
	);
};

// Get employee Summary records in employee list page
export const getUsersSummary = async () => {
	return await axios.get(
		CONFIG.getUserSummary,
		{
			headers: {
				Authorization: "Bearer " + localStorage.getItem("encore-auth-token"),
				"Content-Type": "application/json",
			},
		}
	);
};

export const sendNotifications = async (data) => {
	try {
	  const response = await axios.post(
			CONFIG.sendNotification, 
			data,
			{
				headers: {
					Authorization: "Bearer " + localStorage.getItem("encore-auth-token"),
					"Content-Type": "multipart/form-data",
				},
			}
		);
	  return response;
	} catch (error) {
	  console.error('Error sending notification:', error);
	  throw error;
	}
  };

  export const getNotifications = async ({ page, limit }) => {
	try {
	  const response = await axios.get(CONFIG.getNotifications, {
		params: {
		  page,
		  limit,
		},
		headers: {
			Authorization: "Bearer " + localStorage.getItem("encore-auth-token"),
			"Content-Type": "application/json",
		},
	  });
	  return response;
	} catch (error) {
	  console.error('Error fetching notifications:', error);
	  throw error;
	}
  };

  export const getUsersWithDeviceToken = async () => {
	try {
	  const response = await axios.get(CONFIG.getUsersWithDeviceToken, {
		headers: {
			Authorization: "Bearer " + localStorage.getItem("encore-auth-token"),
			"Content-Type": "application/json",
		},
	  });
	  return response;
	} catch (error) {
	  console.error('Error fetching notifications:', error);
	  throw error;
	}
  };

  export const deleteAdminNotificationById = async (notification_id) => {
	return await axios.delete(
		CONFIG.deleteAdminNotificationById,
		{
			params: {
				notification_id
			  },
			headers: {
				Authorization: "Bearer " + localStorage.getItem("encore-auth-token"),
				"Content-Type": "application/json",
			}
		}
	);
};